<template>
  <div :style="computedHeight">
    <!-- Only display headers and footers when not on the login page -->
    <HeaderBigLogo v-if="
      $route.name === 'Landing'
    " />
    <HeaderSmallLogo v-else-if="
      $route.name !== 'Landing'
    " />

    <div class="main-content" :style="contentStyle">
      <router-view v-slot="{ Component, route }">
        <component :is="Component" v-bind="route.props" />
      </router-view>
    </div>

    <FooterMobile v-if="
      $route.name !== 'Landing'
    " />
  </div>
</template>

<script>
// Importing mobile-specific components
import HeaderSmallLogo from "@/components/Header/HeaderSmallLogo.vue";
import HeaderBigLogo from "@/components/Header/HeaderBigLogo.vue";
import FooterMobile from "@/components/FooterMobile.vue";

import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  components: {
    HeaderSmallLogo,
    HeaderBigLogo,
    FooterMobile
  },
  setup() {
    const display = useDisplay();

    const computedHeight = computed(() => {
      return display.smAndUp.value || window.location.pathname === "/"
        ? "max-height: 100vh"
        : "max-height: 95vh";
    });

    return {
      computedHeight
    };
  }
};
</script>

<style>
html,
body {
  margin: 0px;
  font-family: "Noto Sans KR", sans-serif;
  background-color: #FBFCFC;
  color: #000000;
  max-height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.main-content {
  max-width: 500px;
  margin: 0 auto;
}
</style>