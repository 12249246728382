<template>
  <header class="header" :class="{'widescreen-padding': display.lgAndUp}">
    <div class="first-line">
      <div @click="logoClick">
        <img src="/assets/logo-symbol.png" alt="Menu Icon" class="logo-text" />
      </div>
    </div>
  </header>
</template>

<script>
import { useDisplay } from 'vuetify';

export default {
  data() {
    return {
      errorMessage: "",
      display: {}
    };
  },
  async mounted() {
    this.display = useDisplay();
  },
  methods: {
    logoClick() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.header {
  height: 6vh;
  width: 100% - 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #FBFCFC;
  color: white;
  padding-top: 7px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 90px;
}

.widescreen-padding {
  padding-right: 50px;
  padding-left: 50px;
}

.first-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.logo-text {
  height: 70px;
  cursor: pointer;
}
</style>
