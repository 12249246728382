import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '@/firebaseConfig';

// Setup routes
const routes = [
  {
    path: '/',
    name: 'Landing',
    component: () => import('@/views/LandingPage.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home/HomeV1Page.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/course/:title',
    name: 'Course',
    component: () => import('@/views/Course/CourseV1Page.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import('@/views/Community/CommunityV1Page.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/video/:phraseId',
    name: 'Video',
    component: () => import('@/views/Video/VideoV1Page.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile/ProfileV1Page.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile-info',
    name: 'ProfileInfo',
    component: () => import('@/views/Profile/ProfileInfoV1Page.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/Feedback/FeedbackV1Page.vue'),
    meta: {
      requiresAuth: true
    }
  },
];

// Create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;

  if (requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
